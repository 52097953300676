import React, { Component } from 'react'

export default class JornadaIni extends Component {

    constructor(props){
        super(props);
        this.timer = null;
    }

    handleClick = () => {
        if(this.props.empleado.avisos.length === 0){
            clearTimeout(this.timer);
            this.props.limpiarUsuario();
        }
    }

    handleMensajes = () => {
        this.props.cargarMensajes();
    }

    render() {

        //console.log(this.props.empleado.avisos)
        
        if(this.props.empleado.avisos.length === 0){
            this.timer = setTimeout(() => {
                this.props.limpiarUsuario();
            },4000);
        }
        const date = new Date().toLocaleString("es-ES", {timeZone: this.props.timeZone}).split(" ")[1];
        
        return (
            <div className="card card-body justify-content-center" onClick={this.handleClick} style={{ width: '100%' }}>
                <div className="nombreEmpleado">{this.props.empleado.Nombre} {this.props.empleado.Apellidos}</div>
                <hr />
                <div style={{fontSize: '1.2em'}}>Inicio de la jornada</div>
                <div style={{fontSize: '2em'}}>{date.substr(0,date.length-3)}</div>
                <hr />
                {(this.props.empleado.avisos.length === 0)? '' :
                    <div>
                        <div style={{fontSize: '1.5em', fontWeight: 'bold'}}><span style={{color: 'red'}}>¡¡ATENCION!!</span></div>
                        <div className="mb-3" style={{fontSize: '1.2em'}}>Tiene mensajes sin leer</div>                
                        <div className="btn-group" role="group">
                            <button type="button" className="btn btn-danger p-3 mr-2" onClick={this.handleClick}>Continuar sin leer</button>
                            <button type="button" className="btn btn-success p-3" onClick={this.handleMensajes}>Leer ahora</button>                    
                        </div>
                    </div>
                }
            </div>
        )
    }
}
