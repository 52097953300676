import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'mobx-react'
import Maquinas from './store/Maquinas'
import './App.css';

import Navbar from './components/Navbar'
import Maquina from './components/Maquina'
import Registro from './components/Registro'

//import geolocated from 'react-geolocated'

export default class App extends Component {



  render(){
/*
    if(!this.props.isGeolocationAvailable)
      console.log('Geolocalización no disponible')
    else if(!this.props.isGeolocationEnabled )
      console.log('Geolocalización no habilitada')
    else if(this.props.coords){
      console.log(this.props.coords.latitude);
      console.log(this.props.coords.longitude);
    }else{
      console.log('No hay datos de geolocalización')
    }
*/
    return (      
        <Provider maquinas={Maquinas}>
          <div className="App container">   
            <BrowserRouter>
              <Navbar />

              <Switch>
                <Route exact path="/" component={Maquina} />
                <Route exact path="/fichajes/:id_maquina" component={Maquina} />
                <Route path="/fichajes/registro" component={Registro} />
              </Switch>

            </BrowserRouter> 
          </div>   
        </Provider>   
    );
  }
}
//<Route path="/fichajes/:id_maquina" component={(props) => <Maquina {...props} setMaquina={this.setMaquina} />} />
//<Route exact path="/fichajes/:id_maquina" component={Maquina} />
