import React, { Component } from 'react'

export default class Loading extends Component {
    render() {
        return (
            <div className="card card-body justify-content-center" style={{ width: '100%' }}>
                <h1><i className="fas fa-spinner fa-spin"></i></h1>
            </div>
        )
    }
}
