import React, { Component } from 'react'
import BtnDigito from './BtnDigito'
import BtnOk from './BtnOk'
import BtnNo from './BtnNo'
import '../css/Teclado.css';

export default class Teclado extends Component {

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row justify-content-md-center">
                        <BtnDigito valor="1" setUsuario={this.props.setUsuario} />
                        <BtnDigito valor="2" setUsuario={this.props.setUsuario} />
                        <BtnDigito valor="3" setUsuario={this.props.setUsuario} />
                    </div>
                    <div className="row justify-content-md-center">
                        <BtnDigito valor="4" setUsuario={this.props.setUsuario} />
                        <BtnDigito valor="5" setUsuario={this.props.setUsuario} />
                        <BtnDigito valor="6" setUsuario={this.props.setUsuario} />
                    </div>
                    <div className="row justify-content-md-center">
                        <BtnDigito valor="7" setUsuario={this.props.setUsuario} />
                        <BtnDigito valor="8" setUsuario={this.props.setUsuario} />
                        <BtnDigito valor="9" setUsuario={this.props.setUsuario} />
                    </div>
                    <div className="row justify-content-md-center">
                        <BtnNo limpiarUsuario={() => this.props.limpiarUsuario('Introduzca código de usuario')} />
                        <BtnDigito valor="0" setUsuario={this.props.setUsuario} />
                        <BtnOk aceptarUsuario={this.props.aceptarUsuario} />
                    </div>
                </div>
            </div> 
        )
    }
}
