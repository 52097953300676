import React, { Component } from 'react'

export default class LabelPass extends Component {
    render() {

        let pass=this.props.password;
        if(this.props.clase==="div-pass"){
            pass = "*".repeat(this.props.password.length);
        }

        return (
            <div className={this.props.clase} style={this.props.estilo}>{pass}</div>
        )
    }
}
