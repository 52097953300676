import React, { Component } from 'react'

export default class Fecha extends Component {
    
    constructor(){
        super();
        var today = new Date(),
            date = today.getDate() + '/' + (today.getMonth()+1) + '/' + today.getFullYear()
        
        this.state = {
            date: date
        };
    }

    render() {
        return (
            <div>
                { this.state.date }      
            </div>
        )
    }
}
