import React, { Component } from 'react'


export default class Foto extends Component {

    render() {
        return (
            <div>
                <img src={this.props.srcfoto} alt="Imagen empleado" className="card-img-bottom img-login" />
            </div>
        )
    }
}
