import React, { Component } from 'react'

export default class BtnNo extends Component {
    render() {
        return (
            <div className="col-4">
                <button onClick={this.props.limpiarUsuario} type="button" className="btn btn-danger btnDigito">
                    NO
                </button>
            </div>
        )
    }
}
