import { decorate, observable, action } from 'mobx'
import axios from 'axios';

class Maquinas{

    maquinas = [];
    maquina = {
        idmaquina: "1",
        nombre: null
    }

    constructor () {
        this.maquinas = [];

        axios.get('https://gdpr.opein.com:8081/react/fichaje/maquinas')
        .then(res => {
            this.maquinas = [...res.data];
            const activa = this.maquinas.filter(maq => {
                return maq.IdMaquina.toString() === this.maquina.idmaquina
            })
            this.maquina.nombre = activa[0].Descrip

        })

    }

    setMaquina = (idmaquina) => {       
        this.maquina.idmaquina = idmaquina;
    };
}

decorate(Maquinas, {
    maquinas: observable,
    maquina: observable,
    setMaquina: action
})

const maquinasStore = new Maquinas();

export default maquinasStore