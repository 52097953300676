import React, { Component } from 'react';

class Clock extends Component {

    constructor() {
        super();
        this.state = {
            hour: 0, 
            min: 0, 
            second : 0, 
            time : ""
        };
        this.update = this.update.bind(this);
    }


    update(event){
        const date = new Date().toLocaleString("es-ES", {timeZone: this.props.timeZone}).split(" ")[1];
        const time = date.split(":");        
        this.setState({
            hour: ((Number(time[0]) / 12) * 360) + 90, 
            min: ((Number(time[1]) / 60) * 360) + 90, 
            second : ((Number(time[2]) / 60) * 360) + 90, 
            time : date
        });      
    }
    
    componentDidMount(){           
        setInterval(this.update, 1000);
        this.update();
    }

    render(){
        return (
                <div>                    
                    {this.state.time}
                </div>
            );
    };
};


export default Clock;