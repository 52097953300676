import React, { Component } from 'react'

export default class BtnDigito extends Component {

    handleClick = () => {
        this.props.setUsuario(this.props.valor)
    }


    render() {
        return (
            <div className="col-4">
                <button type="button" className="btn btn-info mb-md-3 mb-2 btnDigito" onClick={this.handleClick}>{this.props.valor}</button>
            </div>
        )
    }
}
