import React, { Component } from 'react'

export default class BtnAusencia extends Component {

    handleClick = () =>{
        this.props.inicioAusencia(this.props.valor)
    }

    render() {
        return (
            <div className="col-6">
                <button className="btn btn-info btn-lg btn-ausencia" style={{width: '100%'}} onClick={this.handleClick}>{this.props.nombre}</button>
            </div>
        )
    }
}
