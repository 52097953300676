import React, { Component } from 'react'
import axios from 'axios'

export default class Mensajes extends Component {

    state = {
        numAviso: 0,
        avisos: this.props.empleado.avisos
    }

    handlePrev = () => {
        let numAviso = (this.state.numAviso===0)? this.state.numAviso : this.state.numAviso-1;
        this.setState({
            numAviso
        })
    }

    handleNext = () => {
        let numAviso = (this.state.numAviso===this.state.avisos.length-1)? this.state.numAviso : this.state.numAviso+1;
        this.setState({
            numAviso
        })
    }

    handleLeido = (e) => {
        let idaviso = e.target.getAttribute('data-index')
        let idempleado = this.props.empleado.IdEmpleado

        axios.post('https://gdpr.opein.com:8081/react/fichaje/mensaje/leer',{
            idaviso: idaviso,
            idempleado: idempleado
        }).then(res=>{
            if(res.data[0].correcto===1){
                
                // Mover al siguiente (o el anterio)
                if(this.state.avisos.length===1)
                    this.props.limpiarUsuario();
                else{
                    this.setState({
                        numAviso: 0
                    })

                    // Eliminar aviso del array
                    this.setState({avisos: this.state.avisos.filter(function(aviso) { 
                        return aviso.IdAviso !== idaviso 
                    })});

                }
            }
        })
    }

    render() {

        return (
            <div className="card card-body justify-content-center" style={{ width: '100%' }}>
                <div className="nombreEmpleado">{this.props.empleado.Nombre} {this.props.empleado.Apellidos}</div>
                <hr />
                <div className="text-left">
                    Mensaje {this.state.numAviso+1} de {this.state.avisos.length}
                    <span className="float-right">
                        <button type="button" className="btn btn-info btn-sm mr-2" onClick={this.handlePrev}><i className="fas fa-angle-left"></i></button>
                        <button type="button" className="btn btn-info btn-sm" onClick={this.handleNext}><i className="fas fa-angle-right"></i></button>
                    </span>
                </div>
                <div key={this.state.avisos[this.state.numAviso].IdAviso}>
                    <div>{this.state.avisos[this.state.numAviso].Aviso}</div>
                    <hr />
                    <button type="button" className="btn btn-danger mr-2" onClick={this.props.limpiarUsuario}>Salir de mensajes</button>
                    <button type="button" className="btn btn-success" data-index={this.state.avisos[this.state.numAviso].IdAviso} onClick={this.handleLeido}>Marcar como leído</button>                    
                </div>
            </div>
        )
    }
}
