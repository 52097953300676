import React, { Component } from 'react'

export default class AusenciaFin extends Component {

    constructor(props){
        super(props);
        this.timer = null;
    }

    handleClick = () => {
        clearTimeout(this.timer);
        this.props.limpiarUsuario();
    }

    render() {
 
        this.timer = setTimeout(() => {
                this.props.limpiarUsuario();
        },4000);

        const inicio = new Date (this.props.datos.inicio)
        const final = new Date(this.props.datos.fecha)

        const HoraIni = inicio.toLocaleString("es-ES", {timeZone: this.props.timeZone}).split(" ")[1];
        const HoraFin = final.toLocaleString("es-ES", {timeZone: this.props.timeZone}).split(" ")[1];  


        return (
            <div className="card card-body justify-content-center" onClick={this.handleClick} style={{ width: '100%' }}>
                <div className="nombreEmpleado">{this.props.empleado.Nombre} {this.props.empleado.Apellidos}</div>
                <hr />
                <div style={{fontSize: '1.2em', fontWeight: 'bold'}}>{this.props.datos.tipo}</div>
                <div style={{fontSize: '1.2em'}}>{HoraIni.substr(0,HoraIni.length-3)} - {HoraFin.substr(0,HoraFin.length-3)}</div>
                <hr />
                <p>
                    Tiempo transcurrido<br/> 
                    <b>{this.props.datos.horas}</b> horas <b>{this.props.datos.minutos}</b> minutos <b>{this.props.datos.segundos}</b> segundos
                </p>
            </div>
        )
    }
}
