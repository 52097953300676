import React, { Component } from 'react';
import BtnAusencia from './BtnAusencia'

export default class Salida extends Component {


    render() {
        let fecha =  this.props.fecha;

        return (
            <div className="card card-body justify-content-center" style={{ width: '100%' }}>
                <div className="nombreEmpleado">{this.props.empleado.Nombre} {this.props.empleado.Apellidos}</div>
                <hr />
                <div style={{marginBottom: '2em'}}>Inicio jornada: {fecha.slice(-8)}</div>
                <div className="row btn-salida-ppal">
                    <div className="col-6">
                    <button className="btn btn-danger btn-lg btn-ausencia" style={{width: '100%'}} onClick={this.props.limpiarUsuario}>CANCELAR</button>
                    </div>
                    <div className="col-6">
                    <button className="btn btn-success btn-lg btn-ausencia" style={{width: '100%'}} onClick={this.props.salidaUsuario}>SALIDA</button>
                    </div>
                </div>

                <div className="row btn-salida-ausencia">
                    <BtnAusencia inicioAusencia={this.props.inicioAusencia} valor="2" nombre="COMIDA" />                    
                    <BtnAusencia inicioAusencia={this.props.inicioAusencia} valor="3" nombre="DESAYUNO" />  
                    
                </div>
                <div className="row">
                    <BtnAusencia inicioAusencia={this.props.inicioAusencia} valor="1" nombre="MÉDICO" />                    
                    <BtnAusencia inicioAusencia={this.props.inicioAusencia} valor="4" nombre="OTROS" />  
                </div>
            </div>
        )
    }
}
