import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import axios from 'axios';

import LabelUsr from './LabelUsr'
import LabelPass from './LabelPass'
import Foto from './Foto'
import Teclado from './Teclado'
import Loading from './Loading'
import JornadaIni from './JornadaIni'
import Salida from './Salida'
import JornadaFin from './JornadaFin'
import AusenciaIni from './AusenciaIni'
import AusenciaFin from './AusenciaFin'
import Mensajes from './Mensajes'
import maquinasStore from '../store/Maquinas';


const border_activo = {
    width: '100%',
    border: '1px solid black',
    color: '#cecece',
    padding: '0.8em',
    marginBottom: '0.8em'    
}
  
const label_empty = {
    width: '100%',
    border: '1px solid #cecece',
    color: '#cecece',
    padding: '0.8em',
    marginBottom: '0.8em'
}

const label_activo = {
    width: '100%',
    border: '1px solid black',
    color: 'black',
    padding: '0.8em',
    marginBottom: '0.8em',
}

const label_inactivo = {
    width: '100%',
    border: '1px solid #cecece',
    color: 'black',
    padding: '0.8em',
    marginBottom: '0.8em'
}


class Maquina extends Component {

    setUsuario = (valor) => {
        let estado = this.state.estado
        if(estado===-1){
            this.setState({                
                estado: 0,
                usuario: valor,
                estilo_usr: label_activo
            })
        }else if(estado===0){
            this.setState({
                usuario: this.state.usuario.concat(valor)
            })    
        }else if(estado===1){
            this.setState({
                estado: 2,
                password: valor,
                estilo_pass: label_activo,
                clase_pass: 'div-pass'
            }) 
        }else if(estado===2){
            this.setState({
                password: this.state.password.concat(valor)
            })  
        }
    }

    limpiarUsuario = () => {

        this.setState({
            estado: -1,
            usuario: 'Código usuario',
            password: 'Contraseña',
            estilo_usr: border_activo,
            estilo_pass: label_empty,
            clase_pass: '',
            srcfoto: '/images/NoUser.jpg',
            empleado: {
                IdEmpleado: null,
                Nombre: null,
                Apellidos: null,
                Avisos: null               
            },
            panel: <Teclado setUsuario={this.setUsuario} limpiarUsuario={this.limpiarUsuario} aceptarUsuario={this.aceptarUsuario} />            
        })
    }


    // FINALIZAR JORNADA
    salidaUsuario = () => {        
        axios.post('https://gdpr.opein.com:8081/react/fichaje/salida',{
            usuario: this.state.usuario,
            idmaquina: this.state.idmaquina
        }).then(res=>{
            if(res.data[0].correcto===0)
                this.limpiarUsuario();
            else{
                // Mostrar en el panel la salida con las dos fechas
                this.setState({                                                     
                    panel: <JornadaFin empleado={this.state.empleado} datos={res.data[0]} limpiarUsuario={this.limpiarUsuario} />
                })   
            }     
        })           
    }

    // INICIAR AUSENCIA
    inicioAusencia = (tipo) => {
        axios.post('https://gdpr.opein.com:8081/react/fichaje/ausencia',{
            usuario: this.state.usuario,
            idmaquina: this.state.idmaquina,
            tipo: tipo
        }).then(res=>{
            if(res.data[0].correcto===0)
                this.limpiarUsuario();
            else{
                this.setState({                                                     
                    panel: <AusenciaIni empleado={this.state.empleado} datos={res.data[0]} limpiarUsuario={this.limpiarUsuario} />
                })  
            }
        })
    }


    cargarMensajes = () => {
        this.setState({
            panel: <Mensajes empleado={this.state.empleado} limpiarUsuario={this.limpiarUsuario} />
        })
    }

    aceptarUsuario = () => {
        let estado = this.state.estado
        if(estado===0){            

            this.setState({
                estado: 1,            
                password: 'Contraseña',
                estilo_usr: label_inactivo,
                estilo_pass: border_activo,                
            })  

        }else if(estado===2){

            this.setState({
                panel: <Loading />
            })

            // Get image and name          
            axios.post('https://gdpr.opein.com:8081/react/fichaje/empleado',{
                usuario: this.state.usuario,
                password: this.state.password
            })
            .then(res => {                  
                let datos = res.data[0];
                if(datos.Error===0){
                    
                    this.setState({
                        srcfoto: (datos.Foto===1)? '/images/' + datos.IdEmpleado + '.jpg' : '/images/NoUser.jpg',
                        empleado: {IdEmpleado: this.state.usuario ,Nombre: datos.Nombre, Apellidos: datos.Apellidos, avisos: datos.avisos}
                    })

                    // INICIO DE JORNADA   
                    if(datos.fichaje.length===0){
                        axios.post('https://gdpr.opein.com:8081/react/fichaje/entrada',{
                            usuario: this.state.usuario,
                            idmaquina: this.state.idmaquina
                        }).then(res2=>{
                            this.setState({                                                     
                                panel: <JornadaIni empleado={this.state.empleado} datos={res2.data[0]} cargarMensajes={this.cargarMensajes} limpiarUsuario={this.limpiarUsuario} />
                            })        
                        })

                    // SOLICITAR TIPO DE SALIDA
                    }else if(datos.fichaje.length>0 && datos.fichaje[0].ausencia===0){
                        this.setState({
                            panel: <Salida empleado={this.state.empleado} fecha={datos.fichaje[0].fecha} limpiarUsuario={this.limpiarUsuario} salidaUsuario={this.salidaUsuario} inicioAusencia={this.inicioAusencia} />
                        })
                    
                    // FIN DE AUSENCIA
                    }else if(datos.fichaje.length>0 && datos.fichaje[0].ausencia===1){
                        axios.post('https://gdpr.opein.com:8081/react/fichaje/ausencia/fin',{
                            usuario: this.state.usuario,
                            idmaquina: this.state.idmaquina
                        }).then(res3=>{                         
                            this.setState({                                                     
                                panel: <AusenciaFin empleado={this.state.empleado} datos={res3.data[0]} limpiarUsuario={this.limpiarUsuario} />
                            })        
                        }) 
                    }
                    
                }else{
                    this.limpiarUsuario('Usuario o contraseña incorrectos.');
                }

            }).catch(error => {
                alert(error)
                this.limpiarUsuario();
                this.setState({
                    panel: <Teclado setUsuario={this.setUsuario} aceptarUsuario={this.aceptarUsuario} limpiarUsuario={this.limpiarUsuario} />    
                })
            })

            
        }
    }
    

    
    componentDidMount = () => {

        if(!this.props.match.params.id_maquina){
            this.props.match.params.id_maquina = "1";
        }

        maquinasStore.setMaquina(this.props.match.params.id_maquina);

        this.setState({
            idmaquina: this.props.match.params.id_maquina
        })

    }

    state = {
        estado: -1,
        idmaquina: -1,        
        usuario: 'Código usuario',
        password: 'Contraseña',
        empleado: {
            IdEmpleado: null,
            Nombre: null,
            Apellidos: null,
            avisos: null
        },
        estilo_usr: border_activo,
        estilo_pass: label_empty,
        clase_pass: '',
        srcfoto: '/images/NoUser.jpg',
        panel: <Teclado setUsuario={this.setUsuario} aceptarUsuario={this.aceptarUsuario} limpiarUsuario={this.limpiarUsuario} />
    }

    render() {

        return (
            <div>
            <div className="card-deck">
                <div className="card card-login">
                                      
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-12 col-5 p-0">
                            <Foto srcfoto={this.state.srcfoto} />  
                            </div>
                            <div className="col-sm-12 col-7">
                            <LabelUsr usuario={this.state.usuario} estilo={this.state.estilo_usr} />
                            <LabelPass password={this.state.password} estilo={this.state.estilo_pass} clase={this.state.clase_pass} />
                            </div>
                        </div>                    
                    </div>
                </div>

                {this.state.panel}
            </div>
            
            </div>
        )
    }
}


export default inject("maquinas")(observer(Maquina));