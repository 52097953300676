import React, { Component } from 'react'

export default class LabelUsr extends Component {


    render() {
        return (
            <div style={this.props.estilo}>{this.props.usuario}</div>
        )
    }
}
