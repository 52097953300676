import React, { Component } from 'react'

export default class AusenciaIni extends Component {

    constructor(props){
        super(props);
        this.timer = null;
    }

    handleClick = () => {
        clearTimeout(this.timer);
        this.props.limpiarUsuario();
    }

    render() {

        this.timer = setTimeout(() => {
            this.props.limpiarUsuario();
        },4000);

        const date = new Date().toLocaleString("es-ES", {timeZone: this.props.timeZone}).split(" ")[1];


        return (
            <div className="card card-body justify-content-center" onClick={this.handleClick} style={{ width: '100%' }}>
                <div className="nombreEmpleado">{this.props.empleado.Nombre} {this.props.empleado.Apellidos}</div>
                <hr />
                <p style={{fontSize: '1.2em', fontWeight: 'bold'}}>{this.props.datos.Ausencia}</p>
                <p style={{fontSize: '1.2em'}}>Inicio de la ausencia</p>
                <p style={{fontSize: '2em'}}>{date.substr(0,date.length-3)}</p>
            </div>
        )
    }
}
