import React, { Component } from 'react'

export default class JornadaFin extends Component {

    constructor(props){
        super(props);
        this.timer = null;
    }

    handleClick = () => {
        clearTimeout(this.timer);
        this.props.limpiarUsuario();
    }

    render(){
        let entrada =  (new Date (this.props.datos.entrada)).toLocaleString("es-ES", {timeZone: this.props.timeZone}).split(" ")[1];
        let salida =  (new Date (this.props.datos.fecha)).toLocaleString("es-ES", {timeZone: this.props.timeZone}).split(" ")[1];
        
        this.timer = setTimeout(() => {
            this.props.limpiarUsuario();
        },4000);
        
        
        return (
            <div className="card card-body justify-content-center" onClick={this.handleClick} style={{ width: '100%' }}>
                <div className="nombreEmpleado">{this.props.empleado.Nombre} {this.props.empleado.Apellidos}</div>
                <hr />
                <p style={{fontSize: '1.2em'}}>Jornada finalizada</p>
                <p style={{fontSize: '2em'}}>{entrada.substr(0,entrada.length-3)} - {salida.substr(0,salida.length-3)}</p>
            </div>
        )
    }
}
