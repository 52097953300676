import React, { Component } from 'react'

export default class BtnOk extends Component {
    render() {
        return (
            <div className="col-4">
                <button onClick={this.props.aceptarUsuario} type="button" className="btn btn-success btnDigito">
                    OK
                </button>
            </div>
        )
    }
}
