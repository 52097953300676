import React, { Component } from 'react';
import { inject, observer } from 'mobx-react'

import Clock from './Clock';
import Fecha from './Fecha';
import maquinasStore from '../store/Maquinas';

class NavBar extends Component {

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark bg-danger fixed-top">
                    <div className="container">
                        <div><span className="navbar-text"><Fecha /></span></div>
                        <div className="navbar-brand">{maquinasStore.maquina.nombre}</div>
                        <div><span className="navbar-text"><Clock timeZone={"Europe/London"} /></span></div>
                    </div>
                </nav>    
                    
            </div>
        )
    }   
}

export default inject("maquinas")(observer(NavBar));
